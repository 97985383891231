import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MainContainer } from '../components/Containers';
import { PageHeading } from '../components/Headings';
import * as en from '../locales/index/en';
import * as pt from '../locales/index/ptBR';

const Home = () => {
  const { locale } = useRouter();

  const { homePageLocale: l } = locale === 'en' ? en : pt;

  return (
    <>
      <Head>
        <title>{l.title}</title>
        <meta name="description" content="Home page" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="icon" href="/favicon.ico" />
      </Head>

      <MainContainer>
        <PageHeading>{l.subtitle}</PageHeading>

        <div className="w-full flex flex-col">
          <p className="mb-10">{l.renderP1()}</p>

          <p className="mb-10">{l.renderP2()}</p>

          <p className="mb-10">{l.renderP3()}</p>

          <p className="mb-10">{l.renderP4()}</p>
        </div>

        <Link
          href="/template"
          className="block mb-6 w-full max-w-sm text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5 text-center"
        >
          {l.ctaBtn}
        </Link>
      </MainContainer>
    </>
  );
};

export default Home;
