import { ReactNode } from 'react';

type PageHeadingProps = {
  children: ReactNode;
};

export const PageHeading = ({ children }: PageHeadingProps) => (
  <h2 className="mb-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center">
    {children}
  </h2>
);
