import { ReactNode } from 'react';

type MainContainerProps = {
  children: ReactNode;
};

export const MainContainer = ({ children }: MainContainerProps) => (
  <main className="flex flex-col items-center max-w-5xl mx-auto">
    {children}
  </main>
);
